import { ICountry } from 'data-transfers/dto/country'

const countries: ICountry[] = [
  {
    id: 123,
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    flag: 'LT',
    translate: {
      en: 'Lithuania',
      lt: 'Lietuva'
    }
  },
  {
    id: 117,
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    flag: 'LV',
    translate: {
      en: 'Latvia',
      lt: 'Latvija'
    }
  },
  {
    id: 68,
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    flag: 'EE',
    translate: {
      en: 'Estonia',
      lt: 'Estija'
    }
  },
  {
    id: 172,
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
    flag: 'PL',
    translate: {
      en: 'Poland',
      lt: 'Lenkija'
    }
  },
  {
    id: 229,
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    flag: 'UA',
    translate: {
      en: 'Ukraine',
      lt: 'Ukraina'
    }
  },
  {
    id: 1,
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
    flag: 'AF',
    translate: {
      en: 'Afghanistan',
      lt: 'Afganistanas'
    }
  },
  {
    id: 2,
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
    flag: 'AL',
    translate: {
      en: 'Albania',
      lt: 'Albanija'
    }
  },
  {
    id: 3,
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
    flag: 'DZ',
    translate: {
      en: 'Algeria',
      lt: 'Alžyras'
    }
  },
  {
    id: 4,
    name: 'American Samoa',
    dial_code: '+1 684',
    code: 'AS',
    flag: 'AS',
    translate: {
      en: 'American Samoa',
      lt: 'Amerikos Samoa'
    }
  },
  {
    id: 5,
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
    flag: 'AD',
    translate: {
      en: 'Andorra',
      lt: 'Andora'
    }
  },
  {
    id: 6,
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
    flag: 'AO',
    translate: {
      en: 'Angola',
      lt: 'Angola'
    }
  },
  {
    id: 7,
    name: 'Anguilla',
    dial_code: '+1 264',
    code: 'AI',
    flag: 'AI',
    translate: {
      en: 'Anguilla',
      lt: 'Angilija'
    }
  },
  // {
  //   id: 8,
  //   name: 'Antarctica',
  //   dial_code: '+672, +64',
  //   code: 'AQ',
  //   flag: 'AQ',
  //   translate: {
  //     en: 'Antarctica',
  //     lt: 'Antarktida'
  //   }
  // },
  {
    id: 9,
    name: 'Antigua and Barbuda',
    dial_code: '+1 268',
    code: 'AG',
    flag: 'AG',
    translate: {
      en: 'Antigua and Barbuda',
      lt: 'Antigva ir Barbuda'
    }
  },
  {
    id: 10,
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    flag: 'AR',
    translate: {
      en: 'Argentina',
      lt: 'Argentina'
    }
  },
  {
    id: 11,
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    flag: 'AM',
    translate: {
      en: 'Armenia',
      lt: 'Armėnija'
    }
  },
  {
    id: 12,
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
    flag: 'AW',
    translate: {
      en: 'Aruba',
      lt: 'Aruba'
    }
  },
  {
    id: 13,
    name: 'Ascension Island',
    dial_code: '+247',
    code: 'AC',
    flag: 'AC',
    translate: {
      en: 'Ascension Island',
      lt: 'Ascension sala'
    }
  },
  {
    id: 14,
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
    flag: 'AU',
    translate: {
      en: 'Australia',
      lt: 'Australija'
    }
  },
  {
    id: 15,
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
    flag: 'AT',
    translate: {
      en: 'Austria',
      lt: 'Austrija'
    }
  },
  {
    id: 16,
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    flag: 'AZ',
    translate: {
      en: 'Azerbaijan',
      lt: 'Azerbaidžanas'
    }
  },
  {
    id: 17,
    name: 'Bahamas',
    dial_code: '+1 242',
    code: 'BS',
    flag: 'BS',
    translate: {
      en: 'Bahamas',
      lt: 'Bahamos'
    }
  },
  {
    id: 18,
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
    flag: 'BH',
    translate: {
      en: 'Bahrain',
      lt: 'Bahreinas'
    }
  },
  {
    id: 19,
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
    flag: 'BD',
    translate: {
      en: 'Bangladesh',
      lt: 'Bangladešas'
    }
  },
  {
    id: 20,
    name: 'Barbados',
    dial_code: '+1 246',
    code: 'BB',
    flag: 'BB',
    translate: {
      en: 'Barbados',
      lt: 'Barbadosas'
    }
  },
  {
    id: 21,
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    flag: 'BY',
    translate: {
      en: 'Belarus',
      lt: 'Baltarusija'
    }
  },
  {
    id: 22,
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    flag: 'BE',
    translate: {
      en: 'Belgium',
      lt: 'Belgija'
    }
  },
  {
    id: 23,
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
    flag: 'BZ',
    translate: {
      en: 'Belize',
      lt: 'Belizas'
    }
  },
  {
    id: 24,
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
    flag: 'BJ',
    translate: {
      en: 'Benin',
      lt: 'Beninas'
    }
  },
  {
    id: 25,
    name: 'Bermuda',
    dial_code: '+1 441',
    code: 'BM',
    flag: 'BM',
    translate: {
      en: 'Bermuda',
      lt: 'Bermudai'
    }
  },
  {
    id: 26,
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
    flag: 'BT',
    translate: {
      en: 'Bhutan',
      lt: 'Butanas'
    }
  },
  {
    id: 27,
    name: 'Bolivia',
    dial_code: '+591',
    code: 'BO',
    flag: 'BO',
    translate: {
      en: 'Bolivia',
      lt: 'Bolivija'
    }
  },
  {
    id: 28,
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
    flag: 'BA',
    translate: {
      en: 'Bosnia and Herzegovina',
      lt: 'Bosnija ir Hercegovina'
    }
  },
  {
    id: 29,
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
    flag: 'BW',
    translate: {
      en: 'Botswana',
      lt: 'Botsvana'
    }
  },
  {
    id: 30,
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    flag: 'BR',
    translate: {
      en: 'Brazil',
      lt: 'Brazilija'
    }
  },
  {
    id: 31,
    name: 'British Virgin Islands',
    dial_code: '+1 284',
    code: 'VG',
    flag: 'VG',
    translate: {
      en: 'British Virgin Islands',
      lt: 'Britanijos mergelių salos'
    }
  },
  {
    id: 32,
    name: 'Brunei',
    dial_code: '+673',
    code: 'BN',
    flag: 'BN',
    translate: {
      en: 'Brunei',
      lt: 'Brunėjus'
    }
  },
  {
    id: 33,
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    flag: 'BG',
    translate: {
      en: 'Bulgaria',
      lt: 'Bulgarija'
    }
  },
  {
    id: 34,
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
    flag: 'BF',
    translate: {
      en: 'Burkina Faso',
      lt: 'Burkina Fasas'
    }
  },
  {
    id: 35,
    name: 'Burma (Myanmar)',
    dial_code: '+95',
    code: 'MM',
    flag: 'MM',
    translate: {
      en: 'Burma (Myanmar)',
      lt: 'Birma (Mianmaras)'
    }
  },
  {
    id: 36,
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
    flag: 'BI',
    translate: {
      en: 'Burundi',
      lt: 'Burundis'
    }
  },
  {
    id: 37,
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
    flag: 'KH',
    translate: {
      en: 'Cambodia',
      lt: 'Kambodža'
    }
  },
  {
    id: 38,
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
    flag: 'CM',
    translate: {
      en: 'Cameroon',
      lt: 'Kamerūnas'
    }
  },
  {
    id: 39,
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
    flag: 'CA',
    translate: {
      en: 'Canada',
      lt: 'Kanada'
    }
  },
  {
    id: 40,
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
    flag: 'CV',
    translate: {
      en: 'Cape Verde',
      lt: 'Žaliasis Kyšulys'
    }
  },
  {
    id: 41,
    name: 'Cayman Islands',
    dial_code: '+1 345',
    code: 'KY',
    flag: 'KY',
    translate: {
      en: 'Cayman Islands',
      lt: 'Kaimanų salos'
    }
  },
  {
    id: 42,
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
    flag: 'CF',
    translate: {
      en: 'Central African Republic',
      lt: 'Centrine Afrikos Respublika'
    }
  },
  {
    id: 43,
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
    flag: 'TD',
    translate: {
      en: 'Chad',
      lt: 'Čadas'
    }
  },
  {
    id: 44,
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
    flag: 'CL',
    translate: {
      en: 'Chile',
      lt: 'Čilė'
    }
  },
  {
    id: 45,
    name: 'China',
    dial_code: '+86',
    code: 'CN',
    flag: 'CN',
    translate: {
      en: 'China',
      lt: 'Kinija'
    }
  },
  {
    id: 46,
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
    flag: 'CX',
    translate: {
      en: 'Christmas Island',
      lt: 'Kalėdų sala'
    }
  },
  {
    id: 47,
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
    flag: 'CC',
    translate: {
      en: 'Cocos (Keeling) Islands',
      lt: 'Kokosų (Kilingo) salos'
    }
  },
  {
    id: 48,
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
    flag: 'CO',
    translate: {
      en: 'Colombia',
      lt: 'Kolumbija'
    }
  },
  {
    id: 49,
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
    flag: 'KM',
    translate: {
      en: 'Comoros',
      lt: 'Komorai'
    }
  },
  {
    id: 50,
    name: 'Congo',
    dial_code: '+242',
    code: 'CG',
    flag: 'CG',
    translate: {
      en: 'Congo',
      lt: 'Kongas'
    }
  },
  {
    id: 51,
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
    flag: 'CK',
    translate: {
      en: 'Cook Islands',
      lt: 'Kuko salos'
    }
  },
  {
    id: 52,
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
    flag: 'CR',
    translate: {
      en: 'Costa Rica',
      lt: 'Kosta Rika'
    }
  },
  {
    id: 53,
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    flag: 'HR',
    translate: {
      en: 'Croatia',
      lt: 'Kroatija'
    }
  },
  {
    id: 54,
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
    flag: 'CU',
    translate: {
      en: 'Cuba',
      lt: 'Kuba'
    }
  },
  {
    id: 55,
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
    flag: 'CY',
    translate: {
      en: 'Cyprus',
      lt: 'Kipras'
    }
  },
  {
    id: 56,
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    flag: 'CZ',
    translate: {
      en: 'Czech Republic',
      lt: 'Čekijos Respublika'
    }
  },
  {
    id: 57,
    name: 'Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD',
    flag: 'CD',
    translate: {
      en: 'Democratic Republic of the Congo',
      lt: 'Kongo Demokratinė Respublika'
    }
  },
  {
    id: 58,
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    flag: 'DK',
    translate: {
      en: 'Denmark',
      lt: 'Danija'
    }
  },
  {
    id: 59,
    name: 'Diego Garcia',
    dial_code: '+246',
    code: 'DG',
    flag: 'DG',
    translate: {
      en: 'Diego Garcia',
      lt: 'Diego Garcia'
    }
  },
  {
    id: 60,
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
    flag: 'DJ',
    translate: {
      en: 'Djibouti',
      lt: 'Džibutis'
    }
  },
  {
    id: 61,
    name: 'Dominica',
    dial_code: '+1 767',
    code: 'DM',
    flag: 'DM',
    translate: {
      en: 'Dominica',
      lt: 'Dominika'
    }
  },
  {
    id: 62,
    name: 'Dominican Republic',
    dial_code: '+1 809, +1 829, +1 849',
    code: 'DO',
    flag: 'DO',
    translate: {
      en: 'Dominican Republic',
      lt: 'Dominikos Respublika'
    }
  },
  {
    id: 63,
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
    flag: 'EC',
    translate: {
      en: 'Ecuador',
      lt: 'Ekvadoras'
    }
  },
  {
    id: 64,
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
    flag: 'EG',
    translate: {
      en: 'Egypt',
      lt: 'Egiptas'
    }
  },
  {
    id: 65,
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
    flag: 'SV',
    translate: {
      en: 'El Salvador',
      lt: 'Salvadoras'
    }
  },
  {
    id: 66,
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
    flag: 'GQ',
    translate: {
      en: 'Equatorial Guinea',
      lt: 'Pusiaujo Gvinėja'
    }
  },
  {
    id: 67,
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
    flag: 'ER',
    translate: {
      en: 'Eritrea',
      lt: 'Eritrėja'
    }
  },
  {
    id: 69,
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
    flag: 'ET',
    translate: {
      en: 'Ethiopia',
      lt: 'Etiopija'
    }
  },
  {
    id: 70,
    name: 'Falkland Islands',
    dial_code: '+500',
    code: 'FK',
    flag: 'FK',
    translate: {
      en: 'Falkland Islands',
      lt: 'Folklando salos'
    }
  },
  {
    id: 71,
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
    flag: 'FO',
    translate: {
      en: 'Faroe Islands',
      lt: 'Farerų salos'
    }
  },
  {
    id: 72,
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
    flag: 'FJ',
    translate: {
      en: 'Fiji',
      lt: 'Fidžis'
    }
  },
  {
    id: 73,
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
    flag: 'FI',
    translate: {
      en: 'Finland',
      lt: 'Suomija'
    }
  },
  {
    id: 74,
    name: 'France',
    dial_code: '+33',
    code: 'FR',
    flag: 'FR',
    translate: {
      en: 'France',
      lt: 'Prancūzija'
    }
  },
  {
    id: 75,
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
    flag: 'GF',
    translate: {
      en: 'French Guiana',
      lt: 'Prancūzijos Gviana'
    }
  },
  {
    id: 76,
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    flag: 'PF',
    translate: {
      en: 'French Polynesia',
      lt: 'Prancūzų Polinezija'
    }
  },
  {
    id: 77,
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
    flag: 'GA',
    translate: {
      en: 'Gabon',
      lt: 'Gabonas'
    }
  },
  {
    id: 78,
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
    flag: 'GM',
    translate: {
      en: 'Gambia',
      lt: 'Gambija'
    }
  },
  {
    id: 79,
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    flag: 'GE',
    translate: {
      en: 'Georgia',
      lt: 'Gruzija'
    }
  },
  {
    id: 80,
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
    flag: 'DE',
    translate: {
      en: 'Germany',
      lt: 'Vokietija'
    }
  },
  {
    id: 81,
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    flag: 'GH',
    translate: {
      en: 'Ghana',
      lt: 'Gana'
    }
  },
  {
    id: 82,
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
    flag: 'GI',
    translate: {
      en: 'Gibraltar',
      lt: 'Gibraltaras'
    }
  },
  {
    id: 83,
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
    flag: 'GR',
    translate: {
      en: 'Greece',
      lt: 'Graikija'
    }
  },
  {
    id: 84,
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
    flag: 'GL',
    translate: {
      en: 'Greenland',
      lt: 'Grenlandija'
    }
  },
  {
    id: 85,
    name: 'Grenada',
    dial_code: '+1 473',
    code: 'GD',
    flag: 'GD',
    translate: {
      en: 'Grenada',
      lt: 'Grenada'
    }
  },
  {
    id: 86,
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
    flag: 'GP',
    translate: {
      en: 'Guadeloupe',
      lt: 'Gvadelupa'
    }
  },
  {
    id: 87,
    name: 'Guam',
    dial_code: '+1 671',
    code: 'GU',
    flag: 'GU',
    translate: {
      en: 'Guam',
      lt: 'Guamas'
    }
  },
  {
    id: 88,
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
    flag: 'GT',
    translate: {
      en: 'Guatemala',
      lt: 'Gvatemala'
    }
  },
  {
    id: 89,
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
    flag: 'GN',
    translate: {
      en: 'Guinea',
      lt: 'Gvinėja'
    }
  },
  {
    id: 90,
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
    flag: 'GW',
    translate: {
      en: 'Guinea-Bissau',
      lt: 'Bisau Gvinėja'
    }
  },
  {
    id: 91,
    name: 'Guyana',
    dial_code: '+592',
    code: 'GY',
    flag: 'GY',
    translate: {
      en: 'Guyana',
      lt: 'Gajana'
    }
  },
  {
    id: 92,
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
    flag: 'HT',
    translate: {
      en: 'Haiti',
      lt: 'Haitis'
    }
  },
  {
    id: 93,
    name: 'Holy See (Vatican City)',
    dial_code: '+39',
    code: 'VA',
    flag: 'VA',
    translate: {
      en: 'Holy See (Vatican City)',
      lt: 'Šventasis Sostas (Vatikanas)'
    }
  },
  {
    id: 94,
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
    flag: 'HN',
    translate: {
      en: 'Honduras',
      lt: 'Hondūras'
    }
  },
  {
    id: 95,
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
    flag: 'HK',
    translate: {
      en: 'Hong Kong',
      lt: 'Honkongas'
    }
  },
  {
    id: 96,
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    flag: 'HU',
    translate: {
      en: 'Hungary',
      lt: 'Vengrija'
    }
  },
  {
    id: 97,
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
    flag: 'IS',
    translate: {
      en: 'Iceland',
      lt: 'Islandija'
    }
  },
  {
    id: 98,
    name: 'India',
    dial_code: '+91',
    code: 'IN',
    flag: 'IN',
    translate: {
      en: 'India',
      lt: 'Indija'
    }
  },
  {
    id: 99,
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    flag: 'ID',
    translate: {
      en: 'Indonesia',
      lt: 'Indonezija'
    }
  },
  {
    id: 100,
    name: 'Iran',
    dial_code: '+98',
    code: 'IR',
    flag: 'IR',
    translate: {
      en: 'Iran',
      lt: 'Iranas'
    }
  },
  {
    id: 101,
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
    flag: 'IQ',
    translate: {
      en: 'Iraq',
      lt: 'Irakas'
    }
  },
  {
    id: 102,
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    flag: 'IE',
    translate: {
      en: 'Ireland',
      lt: 'Airija'
    }
  },
  {
    id: 103,
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
    flag: 'IM',
    translate: {
      en: 'Isle of Man',
      lt: 'Meno sala'
    }
  },
  {
    id: 104,
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
    flag: 'IL',
    translate: {
      en: 'Israel',
      lt: 'Izraelis'
    }
  },
  {
    id: 105,
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
    flag: 'IT',
    translate: {
      en: 'Italy',
      lt: 'Italija'
    }
  },
  {
    id: 106,
    name: "Ivory Coast (Côte d'Ivoire)",
    dial_code: '+225',
    code: 'CI',
    flag: 'CI',
    translate: {
      en: "Ivory Coast (Côte d'Ivoire)",
      lt: 'Dramblio Kaulo Krantas (Dramblio Kaulo Krantas)'
    }
  },
  {
    id: 107,
    name: 'Jamaica',
    dial_code: '+1 876',
    code: 'JM',
    flag: 'JM',
    translate: {
      en: 'Jamaica',
      lt: 'Jamaika'
    }
  },
  {
    id: 108,
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
    flag: 'JP',
    translate: {
      en: 'Japan',
      lt: 'Japonija'
    }
  },
  {
    id: 109,
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
    flag: 'JE',
    translate: {
      en: 'Jersey',
      lt: 'Džersis'
    }
  },
  {
    id: 110,
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
    flag: 'JO',
    translate: {
      en: 'Jordan',
      lt: 'Jordanas'
    }
  },
  {
    id: 111,
    name: 'Kazakhstan',
    dial_code: '+7',
    code: 'KZ',
    flag: 'KZ',
    translate: {
      en: 'Kazakhstan',
      lt: 'Kazachstanas'
    }
  },
  {
    id: 112,
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    flag: 'KE',
    translate: {
      en: 'Kenya',
      lt: 'Kenija'
    }
  },
  {
    id: 113,
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
    flag: 'KI',
    translate: {
      en: 'Kiribati',
      lt: 'Kiribatis'
    }
  },
  {
    id: 114,
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
    flag: 'KW',
    translate: {
      en: 'Kuwait',
      lt: 'Kuveitas'
    }
  },
  {
    id: 115,
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    flag: 'KG',
    translate: {
      en: 'Kyrgyzstan',
      lt: 'Kirgizija'
    }
  },
  {
    id: 116,
    name: 'Laos',
    dial_code: '+856',
    code: 'LA',
    flag: 'LA',
    translate: {
      en: 'Laos',
      lt: 'Laosas'
    }
  },
  {
    id: 118,
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
    flag: 'LB',
    translate: {
      en: 'Lebanon',
      lt: 'Libanas'
    }
  },
  {
    id: 119,
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
    flag: 'LS',
    translate: {
      en: 'Lesotho',
      lt: 'Lesotas'
    }
  },
  {
    id: 120,
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
    flag: 'LR',
    translate: {
      en: 'Liberia',
      lt: 'Liberija'
    }
  },
  {
    id: 121,
    name: 'Libya',
    dial_code: '+218',
    code: 'LY',
    flag: 'LY',
    translate: {
      en: 'Libya',
      lt: 'Libija'
    }
  },
  {
    id: 122,
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
    flag: 'LI',
    translate: {
      en: 'Liechtenstein',
      lt: 'Lichtenšteinas'
    }
  },
  {
    id: 124,
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    flag: 'LU',
    translate: {
      en: 'Luxembourg',
      lt: 'Liuksemburgas'
    }
  },
  {
    id: 125,
    name: 'Macau',
    dial_code: '+853',
    code: 'MO',
    flag: 'MO',
    translate: {
      en: 'Macau',
      lt: 'Makao'
    }
  },
  {
    id: 126,
    name: 'Macedonia',
    dial_code: '+389',
    code: 'MK',
    flag: 'MK',
    translate: {
      en: 'Macedonia',
      lt: 'Makedonija'
    }
  },
  {
    id: 127,
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
    flag: 'MG',
    translate: {
      en: 'Madagascar',
      lt: 'Madagaskaras'
    }
  },
  {
    id: 128,
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
    flag: 'MW',
    translate: {
      en: 'Malawi',
      lt: 'Malavis'
    }
  },
  {
    id: 129,
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    flag: 'MY',
    translate: {
      en: 'Malaysia',
      lt: 'Malaizija'
    }
  },
  {
    id: 130,
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
    flag: 'MV',
    translate: {
      en: 'Maldives',
      lt: 'Maldyvai'
    }
  },
  {
    id: 131,
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
    flag: 'ML',
    translate: {
      en: 'Mali',
      lt: 'Malis'
    }
  },
  {
    id: 132,
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
    flag: 'MT',
    translate: {
      en: 'Malta',
      lt: 'Malta'
    }
  },
  {
    id: 133,
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    flag: 'MH',
    translate: {
      en: 'Marshall Islands',
      lt: 'Maršalo salos'
    }
  },
  {
    id: 134,
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
    flag: 'MQ',
    translate: {
      en: 'Martinique',
      lt: 'Martinika'
    }
  },
  {
    id: 135,
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
    flag: 'MR',
    translate: {
      en: 'Mauritania',
      lt: 'Mauritanija'
    }
  },
  {
    id: 136,
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
    flag: 'MU',
    translate: {
      en: 'Mauritius',
      lt: 'Mauricijus'
    }
  },
  {
    id: 137,
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
    flag: 'YT',
    translate: {
      en: 'Mayotte',
      lt: 'Majotas'
    }
  },
  {
    id: 138,
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    flag: 'MX',
    translate: {
      en: 'Mexico',
      lt: 'Meksika'
    }
  },
  {
    id: 139,
    name: 'Micronesia',
    dial_code: '+691',
    code: 'FM',
    flag: 'FM',
    translate: {
      en: 'Micronesia',
      lt: 'Mikronezija'
    }
  },
  {
    id: 140,
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD',
    flag: 'MD',
    translate: {
      en: 'Moldova',
      lt: 'Moldova'
    }
  },
  {
    id: 141,
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
    flag: 'MC',
    translate: {
      en: 'Monaco',
      lt: 'Monakas'
    }
  },
  {
    id: 142,
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
    flag: 'MN',
    translate: {
      en: 'Mongolia',
      lt: 'Mongolija'
    }
  },
  {
    id: 143,
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
    flag: 'ME',
    translate: {
      en: 'Montenegro',
      lt: 'Juodkalnija'
    }
  },
  {
    id: 144,
    name: 'Montserrat',
    dial_code: '+1 664',
    code: 'MS',
    flag: 'MS',
    translate: {
      en: 'Montserrat',
      lt: 'Montseratas'
    }
  },
  {
    id: 145,
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
    flag: 'MA',
    translate: {
      en: 'Morocco',
      lt: 'Marokas'
    }
  },
  {
    id: 146,
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
    flag: 'MZ',
    translate: {
      en: 'Mozambique',
      lt: 'Mozambikas'
    }
  },
  {
    id: 147,
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
    flag: 'NA',
    translate: {
      en: 'Namibia',
      lt: 'Namibija'
    }
  },
  {
    id: 148,
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
    flag: 'NR',
    translate: {
      en: 'Nauru',
      lt: 'Nauru'
    }
  },
  {
    id: 149,
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
    flag: 'NP',
    translate: {
      en: 'Nepal',
      lt: 'Nepalas'
    }
  },
  {
    id: 150,
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    flag: 'NL',
    translate: {
      en: 'Netherlands',
      lt: 'Nyderlandai'
    }
  },
  {
    id: 151,
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN',
    flag: 'AN',
    translate: {
      en: 'Netherlands Antilles',
      lt: 'Nyderlandų Antilai'
    }
  },
  {
    id: 152,
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
    flag: 'NC',
    translate: {
      en: 'New Caledonia',
      lt: 'Naujoji Kaledonija'
    }
  },
  {
    id: 153,
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
    flag: 'NZ',
    translate: {
      en: 'New Zealand',
      lt: 'Naujoji Zelandija'
    }
  },
  {
    id: 154,
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
    flag: 'NI',
    translate: {
      en: 'Nicaragua',
      lt: 'Nikaragva'
    }
  },
  {
    id: 155,
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
    flag: 'NE',
    translate: {
      en: 'Niger',
      lt: 'Nigeris'
    }
  },
  {
    id: 156,
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    flag: 'NG',
    translate: {
      en: 'Nigeria',
      lt: 'Nigerija'
    }
  },
  {
    id: 157,
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
    flag: 'NU',
    translate: {
      en: 'Niue',
      lt: 'Niue'
    }
  },
  {
    id: 158,
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
    flag: 'NF',
    translate: {
      en: 'Norfolk Island',
      lt: 'Norfolko sala'
    }
  },
  {
    id: 159,
    name: 'North Korea',
    dial_code: '+850',
    code: 'KP',
    flag: 'KP',
    translate: {
      en: 'North Korea',
      lt: 'Šiaurės Korėja'
    }
  },
  {
    id: 160,
    name: 'Northern Mariana Islands',
    dial_code: '+1 670',
    code: 'MP',
    flag: 'MP',
    translate: {
      en: 'Northern Mariana Islands',
      lt: 'Šiaurinės Marianų salos'
    }
  },
  {
    id: 161,
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
    flag: 'NO',
    translate: {
      en: 'Norway',
      lt: 'Norvegija'
    }
  },
  {
    id: 162,
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
    flag: 'OM',
    translate: {
      en: 'Oman',
      lt: 'Omanas'
    }
  },
  {
    id: 163,
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
    flag: 'PK',
    translate: {
      en: 'Pakistan',
      lt: 'Pakistanas'
    }
  },
  {
    id: 164,
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
    flag: 'PW',
    translate: {
      en: 'Palau',
      lt: 'Palau'
    }
  },
  {
    id: 165,
    name: 'Palestine',
    dial_code: '+970',
    code: 'PS',
    flag: 'PS',
    translate: {
      en: 'Palestine',
      lt: 'Palestina'
    }
  },
  {
    id: 166,
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
    flag: 'PA',
    translate: {
      en: 'Panama',
      lt: 'Panama'
    }
  },
  {
    id: 167,
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
    flag: 'PG',
    translate: {
      en: 'Papua New Guinea',
      lt: 'Papua Naujoji Gvinėja'
    }
  },
  {
    id: 168,
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
    flag: 'PY',
    translate: {
      en: 'Paraguay',
      lt: 'Paragvajus'
    }
  },
  {
    id: 169,
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
    flag: 'PE',
    translate: {
      en: 'Peru',
      lt: 'Peru'
    }
  },
  {
    id: 170,
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
    flag: 'PH',
    translate: {
      en: 'Philippines',
      lt: 'Filipinai'
    }
  },
  {
    id: 171,
    name: 'Pitcairn Islands',
    dial_code: '+870',
    code: 'PN',
    flag: 'PN',
    translate: {
      en: 'Pitcairn Islands',
      lt: 'Pitkerno salos'
    }
  },
  {
    id: 173,
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    flag: 'PT',
    translate: {
      en: 'Portugal',
      lt: 'Portugalija'
    }
  },
  {
    id: 174,
    name: 'Puerto Rico',
    dial_code: '+1 787, +1 939',
    code: 'PR',
    flag: 'PR',
    translate: {
      en: 'Puerto Rico',
      lt: 'Puerto Rikas'
    }
  },
  {
    id: 175,
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
    flag: 'QA',
    translate: {
      en: 'Qatar',
      lt: 'Kataras'
    }
  },
  {
    id: 176,
    name: 'Republic of the Congo',
    dial_code: '+242',
    code: 'CG',
    flag: 'CG',
    translate: {
      en: 'Republic of the Congo',
      lt: 'Kongo Respublika'
    }
  },
  {
    id: 177,
    name: 'Reunion Island',
    dial_code: '+262',
    code: 'RE',
    flag: 'RE',
    translate: {
      en: 'Reunion Island',
      lt: 'Reunjono sala'
    }
  },
  {
    id: 178,
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
    flag: 'RO',
    translate: {
      en: 'Romania',
      lt: 'Rumunija'
    }
  },
  {
    id: 179,
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    flag: 'RU',
    translate: {
      en: 'Russia',
      lt: 'Rusija'
    }
  },
  {
    id: 180,
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
    flag: 'RW',
    translate: {
      en: 'Rwanda',
      lt: 'Ruanda'
    }
  },
  {
    id: 181,
    name: 'Saint Barthelemy',
    dial_code: '+590',
    code: 'BL',
    flag: 'BL',
    translate: {
      en: 'Saint Barthelemy',
      lt: 'Šventasis Bartelemis'
    }
  },
  {
    id: 182,
    name: 'Saint Helena',
    dial_code: '+290',
    code: 'SH',
    flag: 'SH',
    translate: {
      en: 'Saint Helena',
      lt: 'Šventoji Elena'
    }
  },
  {
    id: 183,
    name: 'Saint Kitts and Nevis',
    dial_code: '+1 869',
    code: 'KN',
    flag: 'KN',
    translate: {
      en: 'Saint Kitts and Nevis',
      lt: 'Sent Kitsas ir Nevis'
    }
  },
  {
    id: 184,
    name: 'Saint Lucia',
    dial_code: '+1 758',
    code: 'LC',
    flag: 'LC',
    translate: {
      en: 'Saint Lucia',
      lt: 'Sent Liucija'
    }
  },
  {
    id: 185,
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF',
    flag: 'MF',
    translate: {
      en: 'Saint Martin',
      lt: 'Šventasis Martynas'
    }
  },
  {
    id: 186,
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
    flag: 'PM',
    translate: {
      en: 'Saint Pierre and Miquelon',
      lt: 'Sen Pjeras ir Mikelonas'
    }
  },
  {
    id: 187,
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1 784',
    code: 'VC',
    flag: 'VC',
    translate: {
      en: 'Saint Vincent and the Grenadines',
      lt: 'Sent Vincentas ir Grenadinai'
    }
  },
  {
    id: 188,
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
    flag: 'WS',
    translate: {
      en: 'Samoa',
      lt: 'Samoa'
    }
  },
  {
    id: 189,
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
    flag: 'SM',
    translate: {
      en: 'San Marino',
      lt: 'San Marinas'
    }
  },
  {
    id: 190,
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST',
    flag: 'ST',
    translate: {
      en: 'Sao Tome and Principe',
      lt: 'San Tomė ir Prinsipė'
    }
  },
  {
    id: 191,
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    flag: 'SA',
    translate: {
      en: 'Saudi Arabia',
      lt: 'Saudo Arabija'
    }
  },
  {
    id: 192,
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
    flag: 'SN',
    translate: {
      en: 'Senegal',
      lt: 'Senegalas'
    }
  },
  {
    id: 193,
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
    flag: 'RS',
    translate: {
      en: 'Serbia',
      lt: 'Serbija'
    }
  },
  {
    id: 194,
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
    flag: 'SC',
    translate: {
      en: 'Seychelles',
      lt: 'Seišeliai'
    }
  },
  {
    id: 195,
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
    flag: 'SL',
    translate: {
      en: 'Sierra Leone',
      lt: 'Siera Leonė'
    }
  },
  {
    id: 196,
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    flag: 'SG',
    translate: {
      en: 'Singapore',
      lt: 'Singapūras'
    }
  },
  {
    id: 197,
    name: 'Sint Maarten',
    dial_code: '+1 721',
    code: 'SX',
    flag: 'SX',
    translate: {
      en: 'Sint Maarten',
      lt: 'Sint Martenas'
    }
  },
  {
    id: 198,
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    flag: 'SK',
    translate: {
      en: 'Slovakia',
      lt: 'Slovakija'
    }
  },
  {
    id: 199,
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
    flag: 'SI',
    translate: {
      en: 'Slovenia',
      lt: 'Slovėnija'
    }
  },
  {
    id: 200,
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    flag: 'SB',
    translate: {
      en: 'Solomon Islands',
      lt: 'Saliamono salos'
    }
  },
  {
    id: 201,
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
    flag: 'SO',
    translate: {
      en: 'Somalia',
      lt: 'Somalis'
    }
  },
  {
    id: 202,
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    flag: 'ZA',
    translate: {
      en: 'South Africa',
      lt: 'Pietų Afrika'
    }
  },
  {
    id: 203,
    name: 'South Korea',
    dial_code: '+82',
    code: 'KR',
    flag: 'KR',
    translate: {
      en: 'South Korea',
      lt: 'Pietų Korėja'
    }
  },
  {
    id: 204,
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS',
    flag: 'SS',
    translate: {
      en: 'South Sudan',
      lt: 'Pietų Sudanas'
    }
  },
  {
    id: 205,
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
    flag: 'ES',
    translate: {
      en: 'Spain',
      lt: 'Ispanija'
    }
  },
  {
    id: 206,
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
    flag: 'LK',
    translate: {
      en: 'Sri Lanka',
      lt: 'Šri Lanka'
    }
  },
  {
    id: 207,
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
    flag: 'SD',
    translate: {
      en: 'Sudan',
      lt: 'Sudanas'
    }
  },
  {
    id: 208,
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
    flag: 'SR',
    translate: {
      en: 'Suriname',
      lt: 'Surinamas'
    }
  },
  {
    id: 209,
    name: 'Svalbard',
    dial_code: '+47',
    code: 'SJ',
    flag: 'SJ',
    translate: {
      en: 'Svalbard',
      lt: 'Svalbardas'
    }
  },
  {
    id: 210,
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
    flag: 'SZ',
    translate: {
      en: 'Swaziland',
      lt: 'Svazilandas'
    }
  },
  {
    id: 211,
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    flag: 'SE',
    translate: {
      en: 'Sweden',
      lt: 'Švedija'
    }
  },
  {
    id: 212,
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    flag: 'CH',
    translate: {
      en: 'Switzerland',
      lt: 'Šveicarija'
    }
  },
  {
    id: 213,
    name: 'Syria',
    dial_code: '+963',
    code: 'SY',
    flag: 'SY',
    translate: {
      en: 'Syria',
      lt: 'Sirija'
    }
  },
  {
    id: 214,
    name: 'Taiwan',
    dial_code: '+886',
    code: 'TW',
    flag: 'TW',
    translate: {
      en: 'Taiwan',
      lt: 'Taivanas'
    }
  },
  {
    id: 215,
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    flag: 'TJ',
    translate: {
      en: 'Tajikistan',
      lt: 'Tadžikistanas'
    }
  },
  {
    id: 216,
    name: 'Tanzania',
    dial_code: '+255',
    code: 'TZ',
    flag: 'TZ',
    translate: {
      en: 'Tanzania',
      lt: 'Tanzanija'
    }
  },
  {
    id: 217,
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
    flag: 'TH',
    translate: {
      en: 'Thailand',
      lt: 'Tailandas'
    }
  },
  {
    id: 218,
    name: 'Timor-Leste (East Timor)',
    dial_code: '+670',
    code: 'TL',
    flag: 'TL',
    translate: {
      en: 'Timor-Leste (East Timor)',
      lt: 'Rytų Timoras (Rytų Timoras)'
    }
  },
  {
    id: 219,
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
    flag: 'TG',
    translate: {
      en: 'Togo',
      lt: 'Eiti'
    }
  },
  {
    id: 220,
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
    flag: 'TK',
    translate: {
      en: 'Tokelau',
      lt: 'Tokelau'
    }
  },
  {
    id: 221,
    name: 'Tonga Islands',
    dial_code: '+676',
    code: 'TO',
    flag: 'TO',
    translate: {
      en: 'Tonga Islands',
      lt: 'Tongos salos'
    }
  },
  {
    id: 222,
    name: 'Trinidad and Tobago',
    dial_code: '+1 868',
    code: 'TT',
    flag: 'TT',
    translate: {
      en: 'Trinidad and Tobago',
      lt: 'Trinidad and Tobago'
    }
  },
  {
    id: 223,
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
    flag: 'TN',
    translate: {
      en: 'Tunisia',
      lt: 'Tunisas'
    }
  },
  {
    id: 224,
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    flag: 'TR',
    translate: {
      en: 'Turkey',
      lt: 'Turkija'
    }
  },
  {
    id: 225,
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    flag: 'TM',
    translate: {
      en: 'Turkmenistan',
      lt: 'Turkmėnistanas'
    }
  },
  {
    id: 226,
    name: 'Turks and Caicos Islands',
    dial_code: '+1 649',
    code: 'TC',
    flag: 'TC',
    translate: {
      en: 'Turks and Caicos Islands',
      lt: 'Terkso ir Kaikoso salos'
    }
  },
  {
    id: 227,
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
    flag: 'TV',
    translate: {
      en: 'Tuvalu',
      lt: 'Tuvalu'
    }
  },
  {
    id: 228,
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
    flag: 'UG',
    translate: {
      en: 'Uganda',
      lt: 'Uganda'
    }
  },
  {
    id: 230,
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    flag: 'AE',
    translate: {
      en: 'United Arab Emirates',
      lt: 'Jungtiniai Arabų Emyratai'
    }
  },
  {
    id: 231,
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    flag: 'GB',
    translate: {
      en: 'United Kingdom',
      lt: 'Jungtinė Karalystė'
    }
  },
  {
    id: 232,
    name: 'United States',
    dial_code: '+1',
    code: 'US',
    flag: 'US',
    translate: {
      en: 'United States',
      lt: 'Jungtinės Valstijos'
    }
  },
  {
    id: 233,
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
    flag: 'UY',
    translate: {
      en: 'Uruguay',
      lt: 'Urugvajus'
    }
  },
  {
    id: 234,
    name: 'US Virgin Islands',
    dial_code: '+1 340',
    code: 'VI',
    flag: 'VI',
    translate: {
      en: 'US Virgin Islands',
      lt: 'JAV Mergelių salos'
    }
  },
  {
    id: 235,
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    flag: 'UZ',
    translate: {
      en: 'Uzbekistan',
      lt: 'Uzbekistanas'
    }
  },
  {
    id: 236,
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
    flag: 'VU',
    translate: {
      en: 'Vanuatu',
      lt: 'Vanuatu'
    }
  },
  {
    id: 237,
    name: 'Venezuela',
    dial_code: '+58',
    code: 'VE',
    flag: 'VE',
    translate: {
      en: 'Venezuela',
      lt: 'Venesuela'
    }
  },
  {
    id: 238,
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN',
    flag: 'VN',
    translate: {
      en: 'Vietnam',
      lt: 'Vietnamas'
    }
  },
  {
    id: 239,
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
    flag: 'WF',
    translate: {
      en: 'Wallis and Futuna',
      lt: 'Wallis ir Futuna'
    }
  },
  {
    id: 240,
    name: 'Western Sahara',
    dial_code: '+212',
    code: 'EH',
    flag: 'EH',
    translate: {
      en: 'Western Sahara',
      lt: 'Vakarų Sachara'
    }
  },
  {
    id: 241,
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
    flag: 'YE',
    translate: {
      en: 'Yemen',
      lt: 'Jemenas'
    }
  },
  {
    id: 242,
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
    flag: 'ZM',
    translate: {
      en: 'Zambia',
      lt: 'Zambija'
    }
  },
  {
    id: 243,
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
    flag: 'ZW',
    translate: {
      en: 'Zimbabwe',
      lt: 'Zimbabvė'
    }
  }
]

export default countries
