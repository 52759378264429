import { ILanguageInfo } from 'data-transfers/dto'

import { ILocale } from 'types/app'

const languageEn = [
  { name: 'English', native: 'English', code: 'en', label: 'English' },
  { name: 'Lithuanian', native: 'Lietuvių', code: 'lt', label: 'Lithuanian' },
  { name: 'Spanish', native: 'Español', code: 'es', label: 'Spanish' },
  { name: 'German', native: 'Deutsch', code: 'de', label: 'German' },
  { name: 'French', native: 'Français', code: 'fr', label: 'French' },
  {
    name: 'Portuguese',
    native: 'Português',
    code: 'pt',
    label: 'Portuguese'
  },
  { name: 'Russian', native: 'Русский', code: 'ru', label: 'Russian' },
  { name: 'Arabic', native: 'العربية', code: 'ae', label: 'Arabic' },
  {
    name: 'Belarusian',
    native: 'Беларуская',
    code: 'by',
    label: 'Belarusian'
  },
  { name: 'Czech', native: 'Čeština', code: 'cz', label: 'Czech' },
  { name: 'Danish', native: 'Dansk', code: 'dk', label: 'Danish' },
  { name: 'Estonian', native: 'Eesti', code: 'ee', label: 'Estonian' },
  { name: 'Persian', native: 'فارسی', code: 'ir', label: 'Persian' },
  { name: 'Finnish', native: 'Suomi', code: 'fi', label: 'Finnish' },
  { name: 'Irish', native: 'Gaeilge', code: 'ie', label: 'Irish' },
  { name: 'Hebrew', native: 'עברית', code: 'il', label: 'Hebrew' },
  { name: 'Croatian', native: 'Hrvatski', code: 'hr', label: 'Croatian' },
  { name: 'Hungarian', native: 'Magyar', code: 'hu', label: 'Hungarian' },
  { name: 'Bulgarian', native: 'Български', code: 'bg', label: 'Bulgarian' },
  { name: 'Armenian', native: 'Հայերեն', code: 'am', label: 'Armenian' },
  { name: 'Icelandic', native: 'Íslenska', code: 'is', label: 'Icelandic' },
  { name: 'Italian', native: 'Italiano', code: 'it', label: 'Italian' },
  { name: 'Japanese', native: '日本語', code: 'jp', label: 'Japanese' },
  { name: 'Georgian', native: 'ქართული', code: 'ge', label: 'Georgian' },
  { name: 'Korean', native: '한국어', code: 'kr', label: 'Korean' },
  { name: 'Latin', native: 'Latina', code: 'eu', label: 'Latin' },
  {
    name: 'Luxembourgish',
    native: 'Lëtzebuergesch',
    code: 'lu',
    label: 'Luxembourgish'
  },
  { name: 'Latvian', native: 'Latviešu', code: 'lv', label: 'Latvian' },
  { name: 'Dutch', native: 'Nederlands', code: 'nl', label: 'Dutch' },
  { name: 'Norwegian', native: 'Norsk', code: 'no', label: 'Norwegian' },
  { name: 'Polish', native: 'Polski', code: 'pl', label: 'Polish' },
  { name: 'Romanian', native: 'Română', code: 'ro', label: 'Romanian' },
  { name: 'Slovak', native: 'Slovenčina', code: 'sk', label: 'Slovak' },
  {
    name: 'Slovenian',
    native: 'Slovenščina',
    code: 'si',
    label: 'Slovenian'
  },
  { name: 'Albanian', native: 'Shqip', code: 'al', label: 'Albanian' },
  { name: 'Serbian', native: 'Српски', code: 'rs', label: 'Serbian' },
  { name: 'Swedish', native: 'Svenska', code: 'se', label: 'Swedish' },
  { name: 'Turkish', native: 'Türkçe', code: 'tr', label: 'Turkish' },
  { name: 'Ukrainian', native: 'Українська', code: 'ua', label: 'Ukrainian' },
  { name: 'Chinese', native: '中文', code: 'cn', label: 'Chinese' }
]

const languageLt = [
  { name: 'English', native: 'Anglų', code: 'en', label: 'Anglų' },
  { name: 'Lithuanian', native: 'Lietuvių', code: 'lt', label: 'Lietuvių' },
  { name: 'Spanish', native: 'Ispanų', code: 'es', label: 'Ispanų' },
  { name: 'German', native: 'Vokiečių', code: 'de', label: 'Vokiečių' },
  { name: 'French', native: 'Prancūzų', code: 'fr', label: 'Prancūzų' },
  { name: 'Portuguese', native: 'Portugalų', code: 'pt', label: 'Portugalų' },
  { name: 'Russian', native: 'Rusų', code: 'ru', label: 'Rusų' },
  { name: 'Arabic', native: 'Arabų', code: 'ae', label: 'Arabų' },
  { name: 'Belarusian', native: 'Baltarusių', code: 'by', label: 'Baltarusių' },
  { name: 'Czech', native: 'Čekų', code: 'cz', label: 'Čekų' },
  { name: 'Danish', native: 'Danų', code: 'de', label: 'Danų' },
  { name: 'Estonian', native: 'Estų', code: 'ee', label: 'Estų' },
  { name: 'Persian', native: 'Persų', code: 'ir', label: 'Persų' },
  { name: 'Finnish', native: 'Suomių', code: 'fi', label: 'Suomių' },
  { name: 'Irish', native: 'Airių', code: 'ie', label: 'Airių' },
  { name: 'Hebrew', native: 'Hebrajų', code: 'il', label: 'Hebrajų' },
  { name: 'Croatian', native: 'Kroatų', code: 'hr', label: 'Kroatų' },
  { name: 'Hungarian', native: 'Vengrų', code: 'hu', label: 'Vengrų' },
  { name: 'Bulgarian', native: 'Български', code: 'bg', label: 'Bulgarų' },
  { name: 'Armenian', native: 'Armėnų', code: 'am', label: 'Armėnų' },
  { name: 'Icelandic', native: 'Islandų', code: 'is', label: 'Islandų' },
  { name: 'Italian', native: 'Italų', code: 'it', label: 'Italų' },
  { name: 'Japanese', native: 'Japonų', code: 'jp', label: 'Japonų' },
  { name: 'Georgian', native: 'Gruzinų', code: 'ge', label: 'Gruzinų' },
  { name: 'Korean', native: 'Korejiečių', code: 'kr', label: 'Korejiečių' },
  { name: 'Latin', native: 'Lotynų', code: 'eu', label: 'Lotynų' },
  {
    name: 'Luxembourgish',
    native: 'Liuksemburgiečių',
    code: 'lu',
    label: 'Liuksemburgiečių'
  },
  { name: 'Latvian', native: 'Latvių', code: 'lv', label: 'Latvių' },
  { name: 'Dutch', native: 'Olandų', code: 'nl', label: 'Olandų' },
  { name: 'Norwegian', native: 'Norvegų', code: 'no', label: 'Norvegų' },
  { name: 'Polish', native: 'Lenkų', code: 'pl', label: 'Lenkų' },
  { name: 'Romanian', native: 'Rumunų', code: 'ro', label: 'Rumunų' },
  { name: 'Slovak', native: 'Slovakų', code: 'sk', label: 'Slovakų' },
  { name: 'Slovenian', native: 'Slovėnų', code: 'si', label: 'Slovėnų' },
  { name: 'Albanian', native: 'Albanų', code: 'al', label: 'Albanų' },
  { name: 'Serbian', native: 'Serbų', code: 'rs', label: 'Serbų' },
  { name: 'Swedish', native: 'Švedų', code: 'se', label: 'Švedų' },
  { name: 'Turkish', native: 'Turkų', code: 'tr', label: 'Turkų' },
  {
    name: 'Ukrainian',
    native: 'Ukrainiečių',
    code: 'ua',
    label: 'Ukrainiečių'
  },
  { name: 'Chinese', native: 'Kinų', code: 'cn', label: 'Kinų' }
]
// CHinese is not language, 2 main languages are: Mandarin and Cantonese

export const languagesDict: Record<ILocale, ILanguageInfo[]> = {
  en: languageEn,
  lt: languageLt
}

export default languageEn
