import { PropsWithChildren } from 'react'
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles
} from '@floating-ui/react'
import clsx from 'clsx'

import { IModal } from './types'

const ModalDialog = ({
  isOpen,
  onClose,
  children,
  className
}: PropsWithChildren<IModal>) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => !open && onClose?.()
  })

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown'
  })
  const role = useRole(context)

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      transform: 'scale(0.8)',
      opacity: 0
    },
    open: {
      transform: 'scale(1)',
      opacity: 1,
      transitionTimingFunction: 'ease-out'
    },
    close: {
      transitionTimingFunction: 'ease-in',
      opacity: 0,
      transform: 'scale(0.8)'
    },
    common: {},
    duration: { open: 300, close: 200 }
  })

  // Merge all the interactions into prop getters
  const { getFloatingProps } = useInteractions([dismiss, role])

  return (
    <>
      {isMounted && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            className="z-[200]  bg-black/30"
            style={{
              ...styles,
              transform: ''
            }}
          >
            <div
              className="relative flex min-h-screen items-center justify-center overflow-y-auto p-0 sm:p-4"
              style={styles}
            >
              <FloatingFocusManager disabled context={context}>
                <div
                  ref={refs.setFloating}
                  {...getFloatingProps()}
                  className={clsx(className, 'rounded bg-white')}
                >
                  {children}
                </div>
              </FloatingFocusManager>
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  )
}
export default ModalDialog
